<template>
  <div class="admin-login">
    <h1 class="admin-title">{{$t('Login Admin')}}</h1>
    <ValidationObserver ref="validator">
      <form class="admin-login-form" @submit.prevent="login">
        <RFValidation rules="required" name="Email">
          <RFInput label="Email" v-model="admin.email"/>
        </RFValidation>

        <RFValidation rules="required" name="Password">
          <RFInput label="Password" type="password" v-model="admin.password"/>
        </RFValidation>

        <RFButton class="mt-3" label="Login" background="#24425B"/>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import RFInput from '@/components/forms/RFInput'
import RFButton from '@/components/forms/RFButton'
import RFValidation from '@/components/forms/RFValidation'
import { store } from '@/stores'
import { roles } from '@/enums'
import { isAdmin } from '@/helpers/functions'

export default {
  name: 'AdminLogin',
  components: {
    RFInput,
    RFButton,
    RFValidation,
  },
  beforeRouteEnter(to, from, next) {
    if (store.state.userStore.loggedIn) {
      const userRoles = store.state.userStore.user.roles

      if (isAdmin(userRoles)) {
        next({ name: 'admin' })
      } else {
        next({ name: 'profile' })
      }
    } else {
      next()
    }
  },
  data() {
    return {
      admin: {
        email: null,
        password: null,
      }
    }
  },
  methods: {
    async login() {
      const isValid = await this.$refs.validator.validate()

      if (isValid) {
        const loggedAdmin = await authService.login(this.admin)

        if (this.$store.state.userStore.loggedIn) {
          this.$router.push({ name: 'admin' })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  .admin-login {
    .admin-login-form {
      width: 50%;
    }
  }
}
</style>